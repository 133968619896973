import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Icons from "../../components/Icons"
import Layout from "../../components/Layout"
import { Link } from "gatsby"
import React from "react"
import Seo from "../../components/seo"
import SimplePage from "../../components/SimplePage"

const ShopPayFAQ = ({pageContext}) => {
const {
  breadcrumb: { crumbs },
} = pageContext;

return (
  <Layout>
    <Seo title="Shop Pay Installments FAQ" />
    <SimplePage>

      <div className="mb-10 lg:mb-20"><Breadcrumb crumbs={crumbs} crumbLabel="Shop Pay Installments FAQ" crumbSeparator="»" /></div>
      
      <div className="rounded-lg p-5 py-20 lg:p-20 mb-10 bg-gray-300">
        <Icons type="shop_pay_color" className="block min-w-166px max-w-sm mx-auto" />
      </div>
      
      <div className="ele-style">
        <h1 className="font-serif text-gray-450">Shop Pay Installments FAQ</h1>
        <h2>Buy now, pay later with Shop Pay</h2>
        <p>When you choose Shop Pay at checkout, you can split your purchase into 4 equal, 0% interest installment payments - with no additional, hidden, or late fees.* </p>


        <h2 id="what-is-the-option-to-pay-in-installments-on-shop-pay">What is the option to pay in installments on Shop Pay?</h2>
        <p>When checking out with Shop Pay, you now have the option to pay now or later. Paying in installments on Shop Pay allows you to split your purchase amount into 4 equal, biweekly installment payments—with 0% interest, no hidden or late fees, and no impact on your credit score.* In some instances, your first payment is due when you make your purchase; otherwise, your first payment is due 2 weeks after your purchase.</p>


        <h2 id="how-it-works">How Shop Pay Installments work</h2>
        <ol className="pl-4 list-decimal dark-marker mb-4">
          <li>Add items to your cart.</li>
          <li>Check out with Shop Pay.</li>
          <li>Choose to pay in 4 installments.</li>
        </ol>
        <p>Your first payment is either due at checkout or 2 weeks after your purchase. The next 3 remaining installments are automatically charged to your card saved on Shop Pay every 2 weeks. Don't worry, you'll get an email reminder before each payment to remind you.</p>


        <h2 id="is-there-a-min-or-max">Is there a minimum or maximum order to be eligible for Shop Pay Installments?</h2>
        <p>Yes, your cart value must be at least $50 usd and not exceed $3,000 usd to be eligible for Shop Pay Installments.</p>


        <h2 id="are-there-late-fees">Are there late fees?</h2>
        <p>No, there are no late fees if you miss a scheduled payment.</p>
        

        <h2 id="what-if-i-make-a-return-on-a-purchase-made-through-installments-with-shop-pay">What if I make a return on a purchase made through Installments with Shop Pay?</h2>
        <p>If your refund amount is less than the amount remaining on your Shop Pay Installments balance, then you can expect one or both of the following:</p>
        <ul>
          <li>A smaller payment balance on the final payment</li>
          <li>Less future payments on the remaining loan balance</li>
        </ul>
        <p>If your refund amount is more than the amount remaining on your Shop Pay Installments balance, then you can expect to receive the difference as a refund to your original debit card or credit card payment method within 3 to 10 business days.</p>


        <h2 id="which-payment-methods-are-accepted">Which payment methods are accepted if I use the option to pay Installments on Shop Pay?</h2>
        <p>The installments option on Shop Pay is available on debit and credit cards.</p>


        <h2 id="have-more-questions">Have more questions?</h2>
        <ul>
          <li>For questions about installment payments on Shop Pay, visit <a href="https://shop.affirm.com/help" target="_blank" title="Shop Affirm Help" rel="nofollow noopener noreferrer">shop.affirm.com/help</a>.</li>
          <li>For questions about Shop Pay or the Shop App, visit <a href="https://shop.app/help/shop-pay" target="_blank" title="Shop Pay Help" rel="nofollow noopener noreferrer">shop.app/help/shop-pay</a>.</li>
        </ul>


        <p><em>*Payment options are offered by Affirm and are subject to eligibility check and might not be available in all states. California residents: Affirm Loan Services, LLC is licensed by the Department of Business Oversight. Loans are made or arranged pursuant to California Financing Law license.</em></p>      
      </div>

      <Link to="/store" className="btn inline-block text-center mt-10 w-full md:w-auto text-xl">Start shopping</Link>
    
    </SimplePage>
  </Layout>
)};

export default ShopPayFAQ
